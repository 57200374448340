import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/documents/Hero";
import Progress from "../../sections/documents/Progress";
import Content1 from "../../sections/documents/Content1";
import Content2 from "../../sections/documents/Content2";
import CTA from "../../sections/documents/CTA";

const Documents = () => {
  return (
    <>
      <PageWrapper>
        <Hero className="pt-11 pt-lg-34 pb-8 pb-lg-33" />
        <Progress className="pb-lg-34 border-top border-default-color-1" />
        <Content1 className="pb-10 pb-sm-5 pb-lg-14" />
        <Content2 className="pb-0 pb-lg-31" />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default Documents;
